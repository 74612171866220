import { pressLinks } from 'utils';
import { useAppContext } from 'contexts/AppContext';
import Image from 'next/image';
import Carrousel from './Carrousel/Carrousel';
import styles from './XoMedia.module.scss';

const XoMedia = () => {
    
    const { isMobile } = useAppContext();
    const media = ["NewYorkTimes", "Forbes", "MarieClaire", "Newsweek", "Buzzfeed"]
    
    return ( 
        <div className={`${styles.xoMedia} xoMedia`}>
            {isMobile ? 
            <Carrousel media={media} />
            :
            <div className={styles.xoMedia__container}>
                {media.map((name, i)=>
                    <div key={name}>
                        <a href={`${pressLinks[name]}`} target="_blank" rel="noreferrer" aria-label={name}>
                            <div key={name}>
                                    <Image src={`/media/${name}.png`} layout="fill" objectFit="contain" alt={`${name} logo`} />
                            </div>
                        </a>
                    </div>
                )}
            </div>
            }
        </div>
     );
}
 
export default XoMedia;