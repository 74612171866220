import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import SimplePlayer from "@/components/elements/SimplePlayer/SimplePlayer";

import "swiper/css";
import "swiper/css/pagination"
import "swiper/css/navigation"

import SwiperCore, {
  Pagination, Navigation
} from 'swiper';

SwiperCore.use([Pagination, Navigation]);

import styles from './MobileTrailers.module.scss';


const MobileTrailers = ({trailers}) => {
    return ( 
        <section className={`${styles.mobileTrailers} mobileTrailers`}>
          <Swiper 
          className="mySwiper"
          slidesPerView={'auto'}
          spaceBetween={10}
          centeredSlides={true}
          navigation={true}
          >
            {trailers.map((trailer, i)=>
                <SwiperSlide key={trailer.id} className={styles.mobileTrailers__swiperSlide} >
                    <div>
                      <SimplePlayer video={trailer} controls={true} />
                    </div>
                </SwiperSlide>
            )}
          </Swiper>
        </section>
     );
}
 
export default MobileTrailers;