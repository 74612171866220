import { motion } from 'framer-motion';

import styles from './PlayButton.module.scss';

const PlayButton = ({size=52}) => {
    return ( 
        <motion.div className={styles.playButton} initial={{opacity: 0, scale:.75}} animate={{opacity: 1, scale:1}}>
            <svg width={size} height={size} viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M22.9001 36.075L34.9642 27.0334C35.6617 26.5167 35.6617 25.4834 34.9642 24.9667L22.9001 15.925C22.0476 15.2792 20.8334 15.8992 20.8334 16.9584V35.0417C20.8334 36.1009 22.0476 36.7209 22.9001 36.075ZM26.0001 0.166687C11.7401 0.166687 0.166748 11.74 0.166748 26C0.166748 40.26 11.7401 51.8334 26.0001 51.8334C40.2601 51.8334 51.8334 40.26 51.8334 26C51.8334 11.74 40.2601 0.166687 26.0001 0.166687ZM26.0001 46.6667C14.6076 46.6667 5.33342 37.3925 5.33342 26C5.33342 14.6075 14.6076 5.33335 26.0001 5.33335C37.3926 5.33335 46.6667 14.6075 46.6667 26C46.6667 37.3925 37.3926 46.6667 26.0001 46.6667Z" fill="white" fillOpacity="0.7"/>
            </svg>
        </motion.div>
     );
}
 
export default PlayButton;