import styles from './SectionTitle.module.scss';

const SectionTitle = ({children, theme="white", wMargin=true, dividerColor=false, align="center", className="", hasLine=true}) => {
    return ( 
        <div className={`${styles.sectionTitle} ${styles[`sectionTitle-${theme}`]} ${wMargin ? styles["sectionTitle-wMargin"] : ""} ${styles[`sectionTitle-${align}`]}`}>
            <div>
                <h3 className={className}>{children}</h3>
                <div className={(hasLine) ? styles.sectionTitle__divider:''} style={dividerColor ? {backgroundColor: `${dividerColor}`} : null}></div>
            </div>
        </div>
     );
}
 
export default SectionTitle;