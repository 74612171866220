import { pressLinks } from "utils";
import Image from 'next/image';
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from 'swiper';
import "swiper/css";
import "swiper/css/pagination";
import styles from '../XoMedia.module.scss';

SwiperCore.use([Pagination]);

const Carrousel = ({media}) => {
    return ( 
        <div className={styles.carrousel}>
            <div className={styles.carrousel__container}>
                <Swiper 
                pagination={true}
                slidesPerView={'auto'}
                spaceBetween={15}
                centeredSlides={true}
                className="mySwiper">
                    {media.map((item, i)=>
                        <SwiperSlide key={item}>
                            <div className={styles.carrousel__slide}>
                                <a href={`${pressLinks[item]}`} target="_blank" rel="noreferrer" aria-label={item}>
                                    <div>
                                        <div key={item}>
                                                <Image src={`/media/${item}.png`} layout="fill" objectFit="contain" alt={`${item} logo`} />
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </SwiperSlide>
                    )}
                </Swiper>
            </div>
        </div>
     );
}
 
export default Carrousel;