
import React, { useRef, useState, useEffect } from "react";
import { useOnScreen } from 'utils';
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from 'swiper';
import Image from 'next/image';
import Link from 'next/link';
import Button from "@/components/elements/Button/Button";
import "swiper/css";
import "swiper/css/pagination"
import styles from './Carrousel.module.scss';
import styles1 from '../Testimonials.module.scss';
import TestimonialsCard from '../TestimonialsCard';




SwiperCore.use([Pagination]);

const Carrousel = ({testimonials,testimonialsRef}) => {

  const [show, setShow] = useState(false)
  const carrouselRef = useRef()
  const isIntersecting = useOnScreen(carrouselRef)
  const location = "Testimonials module"

  useEffect(()=>{
    isIntersecting && setShow(true) 
  }, [isIntersecting])

  return (
      <section className={styles.carrousel}>
        <div className={`${styles.carrousel__container} ${!show ? styles['carrousel__container-hidden'] : ''}`} ref={carrouselRef}>
          <section className={styles1.testimonials}>
            <div className={`${styles1.testimonials__testimonialsContainer} ${!show ? styles['testimonials__testimonialsContainer-hidden'] : ''}`}>
          <Swiper 
	    pagination={true}
	    slidesPerView={'auto'}
	    spaceBetween={0}
	    centeredSlides={true}
	    style={{
              marginLeft: -window.innerWidth,
	    }}
	  >
              {testimonials.map((testimonial, i)=>
                  <SwiperSlide key={testimonial.customer}>
                      <TestimonialsCard testimonial={testimonial} testimonialsRef={testimonialsRef} i={i}/>
                  </SwiperSlide>
              )}
          </Swiper>
	    </div>
	  </section>
        </div>
        <div className={`${styles.carrousel__ctaContainer} ${!show ? styles['carrousel__ctaContainer-hidden'] : ''}`}>
          <Link href="/about" passHref>
             <span>
               <Button theme="transparent" tracking={{destination:"/about", location}}>
                 Learn more about afterglow
               </Button>
             </span>
          </Link>
        </div>
      </section>
  )
}

export default Carrousel;
