
import { useEffect, useState, useRef } from 'react';
import { getPublicVideo } from 'api';
import { useOnScreen } from 'utils/index';
import { useAppContext } from 'contexts/AppContext';
import ReactPlayer from 'react-player'
import XoLoader from '../XoLoader/XoLoader';
import PlayButton from '../PlayButton/PlayButton';
import styles from './SimplePlayer.module.scss';


const SimplePlayer = ({video, controls=false}) => {
    
    const [show, setShow] = useState({playButton: false})
    const playElRef = useRef()
    const isIntersecting = useOnScreen(playElRef)
    const {isMobile} = useAppContext()
    const [player, setPlayer] = useState({
        loading: false,
        src: null,
        clicked: false,
        playing: false,
        controls,
        isHardPaused: false,
    })
    const playerRef = useRef(player)

    useEffect(()=>{
        playerRef.current = player
    },[player])

    useEffect(()=>{
        if(isIntersecting){
            let timeout = setTimeout(() => {
                    setShow({...show, playButton:true})
                }, 200);
            
            (player.clicked && !player.isHardPaused) && setPlayer({...player, playing: true})
            
            return () => {
                clearTimeout(timeout)
            }

        }else{
            setPlayer({...player, playing: false})
        }
    }, [isIntersecting])

    async function fetchVideo(){
        setPlayer({...playerRef.current, loading: true})
        const response = await getPublicVideo(video.route)
        setPlayer({...playerRef.current, loading: false, src: response.data.src})
    }

    async function handlePlayerClick(){
        if(!player.clicked){
            fetchVideo()
        }
        setPlayer({...player, loading: !player.clicked, clicked: true, playing: !player.playing, isHardPaused: player.playing})
    }

    return ( 

        <div className={styles.simplePlayer}>

            <div className={`${styles.simplePlayer__video} ${!player.clicked ? styles['simplePlayer__video-hidden'] : styles['simplePlayer__video-showing']}`} onClick={(e)=>{handlePlayerClick(), e.preventDefault()}}>
                {player.loading &&
                <>
                    <div style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
                        <XoLoader size={35} />
                    </div>
                </>
                }
                <div className={`${styles.simplePlayer__preview} ${show.playButton ? styles['simplePlayer__preview-animate'] : ''}`} ref={playElRef}>
                        {show.playButton && !player.clicked &&
                            <PlayButton  size={isMobile ? 30 : undefined} />
                        }
                    <img src={`${video.placeholder}`} alt={`${video.title} trailer`} style={{maxHeight: "100%"}} width="100%" height="100%" />
                </div>
                
                <div className={`${styles.simplePlayer__player}`}>
                    <ReactPlayer 
                    url={player.src}
                    playing={player.playing}
                    width="100%"
                    height="100%"
                    controls={player.controls}
                    />
                </div>
            
            </div>

        </div>

     );
}
 
export default SimplePlayer;