import {useState, useEffect, useRef } from 'react';
import { useRouter } from 'next/router'
import { useOnScreen, track, useExperiment } from 'utils';
import { getCSRFCookie } from 'api';
import { useAppContext } from 'contexts/AppContext';
import Link from 'next/link';
import MainHeader from '../components/modules/MainHeader/MainHeader';
import XoCategories from '@/components/modules/XoCategories/XoCategories';
import HeroTestimonial from '@/components/modules/HeroTestimonial/HeroTestimonial';
import XoMedia from '@/components/modules/XoMedia/XoMedia';
import Testimonials from '@/components/modules/Testimonials/Testimonials';
import Button from '@/components/elements/Button/Button';
import styles from '../styles/Home.module.scss';
import SectionTitle from '@/components/elements/SectionTitle/SectionTitle';
import TrailersSection from '@/components/modules/TrailersSection/TrailersSection';
import FullScreenLoader from '@/components/modules/FullScreenLoader/FullScreenLoader';


function Home() {

  const router = useRouter()
  const redirect = router.query['redirect'] || router.asPath.match(new RegExp(`[&?]redirect=(.*)(&|$)`))
  const plansRef = useRef()
  const isIntersectingPlans = useOnScreen(plansRef)
  const [showPlans, setShowPlans] = useState(false)
  const {userStatus} = useAppContext()
  const pwywVariant = useExperiment('Fk5LVZ41T1aabwJFx9z1Vw')

  useEffect(()=>{
    const defaultPath = userStatus.loggedIn ? '/watch' : '/login'
    getCSRFCookie().then(()=>{
        const redirectPath = redirect || defaultPath
        router.push(redirectPath)
    })
  }, [])

  useEffect(()=>{
    if(isIntersectingPlans){
      setShowPlans(true)
      track('Join Now - High quality erotica - Variant Displayed',
      {
        variant: pwywVariant
      }
      )
    }
  }, [isIntersectingPlans])

  // /NEXTFILE x2
  const trailers = [
    {id: 2, title: "U up?", placeholder: "/next/trailers/U up.webp", route: "u-up-trailer"},
    {id: 1, title: "Lip Service", placeholder: "/next/trailers/Lip service.webp", route: "lip-service-trailer"},
  ]

  return (

    <>
      <FullScreenLoader />
    </>
  )
}

export default Home;
